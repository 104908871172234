import * as React from 'react';
import { SAMPLE_TEXT, SAMPLE_TWITTER_HANDLES } from '../../SampleData';
import ViewType from './ViewType';

const getSampleDataFromViewType = (type: ViewType): Array<string> => {
  if (ViewType.CUSTOM === type) {
    return SAMPLE_TEXT;
  } else if (ViewType.TWITTER === type) {
    return SAMPLE_TWITTER_HANDLES;
  }

  return [];
};

const renderSampleText = ({ type, onPopulateWithSample }: SamplesProps) => {
  const sampleItems = [];
  const sampleData = getSampleDataFromViewType(type);

  for (let i = 0; i < sampleData.length; i++) {
    sampleItems.push(
      <li
        key={i}
        className='sample-item'
        role='button'
        onClick={onPopulateWithSample}>
        {sampleData[i]}
      </li>
    );
  }

  return sampleItems;
};

interface SamplesProps {
  type: ViewType;
  onPopulateWithSample: (event: any) => void;
}
const Samples = (props: SamplesProps) => {
  return (
    <div className='row justify-content-center'>
      <div className='col'>
        <b>Samples</b>
        <br />
        <ul>{renderSampleText(props)}</ul>
      </div>
    </div>
  );
};

export default Samples;

import * as React from 'react';
import { ViewProps } from '../core/ViewProps';
import AnalyzeReset from '../core/CommonButtons';
import ViewType from '../core/ViewType';
import Samples from '../core/Samples';
import { getSentimentEmojiFromTwitterUser } from '../../../clients/NlpEmojiService';
import Tweets from './Tweets';

enum SearchType {
  BY_HANDLE = 'byHandle',
  BY_GEO = 'byGeo'
}
interface TwitterState {
  errorMessage: string;
  activeSearchType: string;
  inputValue: string;
  postIds: string[];
}
class Twitter extends React.Component<ViewProps, TwitterState> {
  private readonly MIN_WORDS_FOR_ANALYSIS = 2;

  state = {
    errorMessage: '',
    activeSearchType: SearchType.BY_HANDLE,
    inputValue: '',
    postIds: []
  };

  render() {
    return (
      <>
        <div className='row justify-content-center'>
          <div className='col-lg-4 col-md-6 text-center'>
            {this.renderSearchView()}
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col text-center'>
            <AnalyzeReset
              isLoading={this.props.isLoading}
              isInputValid={this.isInputInvalid}
              handleAnalyzeClick={this.handleAnalyzeClick}
              onReset={this.props.onReset}
            />
          </div>
        </div>
        <div className='row justify-content-center'>
          <Tweets postIds={this.state.postIds} />
        </div>

        <hr />

        <Samples
          type={ViewType.TWITTER}
          onPopulateWithSample={this.populateWithSample}
        />
      </>
    );
  }

  private readonly renderSearchView = () => {
    if (SearchType.BY_HANDLE === this.state.activeSearchType) {
      return this.renderUserHandleSearch();
    } else if (SearchType.BY_GEO === this.state.activeSearchType) {
      return this.renderGeoSearch();
    }
  };

  private readonly renderPickSearchType = () => {
    const { activeSearchType } = this.state;

    return (
      <>
        <div className='form-check form-check-inline'>
          <p>
            <input
              checked={SearchType.BY_HANDLE === activeSearchType}
              className='form-check-input'
              type='radio'
              value={SearchType.BY_HANDLE}
              onChange={this.onRadioChange}
            />
            <label className='form-check-label'>By Handle</label>
          </p>
        </div>
        <div className='form-check form-check-inline'>
          <p>
            <input
              className='form-check-input'
              checked={SearchType.BY_GEO === activeSearchType}
              type='radio'
              value={SearchType.BY_GEO}
              onChange={this.onRadioChange}
            />
            <label className='form-check-label'>By Area</label>
          </p>
        </div>
      </>
    );
  };

  private readonly renderUserHandleSearch = () => {
    return (
      <div className='input-group mb-3'>
        <div className='input-group-prepend'>
          <span className='input-group-text'>@</span>
        </div>
        <input
          className='form-control'
          placeholder='Twitter handle'
          aria-label='Twitter handle'
          type='text'
          size={50}
          value={this.state.inputValue}
          onChange={this.onInputValueChange}
        />
      </div>
    );
  };

  private readonly renderGeoSearch = () => {
    return (
      <div>
        <p>Map here</p>
      </div>
    );
  };

  private readonly onRadioChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget instanceof HTMLElement) {
      this.setState({ activeSearchType: event.currentTarget.value });
    }
  };

  private readonly onInputValueChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget instanceof HTMLElement) {
      this.setState({ inputValue: event.currentTarget.value });
    }
  };

  private readonly isInputInvalid = () => {
    return this.state.inputValue.length < this.MIN_WORDS_FOR_ANALYSIS;
  };

  private readonly handleAnalyzeClick = async () => {
    const { onAnalysisStarted, onAnalysisCompleted, onError } = this.props;

    if (this.isInputInvalid()) {
      const errorMessage = `You need at least ${
        this.MIN_WORDS_FOR_ANALYSIS
      } characters to get an emoji for a twitter user.`;
      this.setState({ errorMessage });

      return;
    }

    onAnalysisStarted();

    try {
      const result = await getSentimentEmojiFromTwitterUser(
        this.state.inputValue
      );
      const emoji = result.emoji ? result.emoji : null;

      this.setState({ postIds: result.postIds });
      const emojiResult = emoji
        ? {
            emojiCode: emoji && emoji.code,
            emojiText: emoji && emoji.name
          }
        : null;
      onAnalysisCompleted(emojiResult);
    } catch (requestError) {
      onError();
    }
  };

  private readonly populateWithSample = (event: any) => {
    this.setState(
      { inputValue: event.currentTarget.innerHTML },
      this.handleAnalyzeClick
    );
  };
}

export default Twitter;

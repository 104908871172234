import React from 'react';

const EMOJI_CONTAINER_HEIGHT = '75px';
const DEFAULT_EMOJI_CODE = 0x26d4;

interface EmojiProps {
  code: string;
}
const Emoji = (props: EmojiProps) => {
  let emojiCode = DEFAULT_EMOJI_CODE;

  if (props.code) {
    emojiCode = parseInt(props.code.replace('U+', '0x'));
  }

  return (
    <>
      <div className='row justify-content-center'>
        <p>
          <span style={{ fontSize: EMOJI_CONTAINER_HEIGHT }}>
            {String.fromCodePoint(emojiCode)}
          </span>
        </p>
      </div>
    </>
  );
};

export default Emoji;

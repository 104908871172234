const SAMPLE_TEXT = [
  "This is pretty cool! I can't wait to see what this thing can do!",
  'Hmm this is a rather interesting little app.',
  "It's really sad that not enough puppies are adopted every year.",
  "This thing totally sucks! Who's idea was this anyways?!"
];

const SAMPLE_TWITTER_HANDLES = [
  '@realDonaldTrump',
  '@katyperry',
  '@BarackObama',
  '@rihanna',
  '@justinbieber',
  '@ladygaga',
  '@taylorswift13',
  '@TheEllenShow',
  '@Cristiano'
];

export { SAMPLE_TEXT, SAMPLE_TWITTER_HANDLES };

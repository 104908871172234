import * as React from 'react';
import { getSentimentEmoji } from '../../../clients/NlpEmojiService';
import { ViewProps } from '../core/ViewProps';
import AnalyzeReset from '../core/CommonButtons';
import Samples from '../core/Samples';
import ViewType from '../core/ViewType';

interface CustomInputState {
  userInput: string;
  errorMessage: string;
}
class CustomInput extends React.Component<ViewProps, CustomInputState> {
  private readonly MIN_WORDS_FOR_ANALYSIS = 3;
  private readonly INSTRUCTIONS =
    'Enter a sentence with at least 3 words and no more than 1000 characters.';

  state = {
    userInput: '',
    errorMessage: ''
  };

  render() {
    const { errorMessage, userInput } = this.state;

    return (
      <>
        <div className='form-row d-flex justify-content-center'>
          <div className='form-group col col-lg-6 col-md-9 text-center'>
            <textarea
              className='form-control'
              rows={5}
              value={userInput}
              aria-label='Enter text'
              placeholder={this.INSTRUCTIONS}
              onChange={this.handleUserInput}
            />
          </div>
        </div>

        {errorMessage ? (
          <div
            id='error-mesage-container'
            className='row justify-content-center'>
            <div className='col'>{errorMessage}</div>
          </div>
        ) : (
          <br />
        )}

        <div className='row justify-content-center'>
          <div className='col text-center'>
            <AnalyzeReset
              isLoading={this.props.isLoading}
              isInputValid={this.isInputValid}
              handleAnalyzeClick={this.handleAnalyzeClick}
              onReset={this.handleResetClick}
            />
          </div>
        </div>

        <hr />

        <Samples
          type={ViewType.CUSTOM}
          onPopulateWithSample={this.populateWithSample}
        />
      </>
    );
  }

  private readonly handleUserInput = (inputEvent: any) => {
    const inputText = inputEvent.currentTarget.value;
    if (this.state.errorMessage.length > 0) {
      this.setState({
        userInput: inputText,
        errorMessage: ''
      });
    } else {
      this.setState({ userInput: inputText });
    }
  };

  private readonly handleResetClick = () => {
    this.setState({ userInput: '', errorMessage: '' }, this.props.onReset);
  };

  private readonly handleAnalyzeClick = async () => {
    const { onAnalysisStarted, onAnalysisCompleted, onError } = this.props;

    if (this.isInputValid()) {
      const errorMessage = `You need at least ${
        this.MIN_WORDS_FOR_ANALYSIS
      } words to get an emoji.`;
      this.setState({ errorMessage });

      return;
    }

    onAnalysisStarted();

    try {
      const result = await getSentimentEmoji(this.state.userInput);
      onAnalysisCompleted({ emojiCode: result.code, emojiText: result.name });
    } catch (requestError) {
      onError();
    }
  };

  private readonly isInputValid = () => {
    return (
      !this.state.userInput ||
      this.state.userInput.split(' ').length <= this.MIN_WORDS_FOR_ANALYSIS
    );
  };

  private readonly populateWithSample = (event: any) => {
    this.setState({ userInput: event.currentTarget.innerHTML });
  };
}

export default CustomInput;

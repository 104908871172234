import CustomInput from '../custom/CustomInput';
import Twitter from '../twitter/Twitter';
import ViewType from './ViewType';

const navItems = [
  {
    type: ViewType.CUSTOM,
    name: 'Custom Input',
    component: CustomInput
  },
  {
    type: ViewType.TWITTER,
    name: 'Twitter',
    component: Twitter
  }
];

export default navItems;

import * as React from 'react';
//@ts-ignore
import { TwitterTweetEmbed } from 'react-twitter-embed';

interface TweetsProps {
  postIds: number[];
}
class Tweets extends React.Component<TweetsProps, { isOpen: boolean }> {
  state = {
    isOpen: false
  };

  render() {
    const { isOpen } = this.state;
    const { postIds } = this.props;

    return (
      <div>
        <div className={'row justify-content-center'}>
          {postIds.length > 0 ? this.renderShowTweetsLink() : null}
        </div>
        {isOpen ? this.renderTweets() : null}
      </div>
    );
  }

  private readonly renderShowTweetsLink = () => {
    return (
      <a href='/#' onClick={this.toggleShowTweets}>
        <b>
          {this.state.isOpen ? 'Hide' : `Show ${this.props.postIds.length}`}{' '}
          tweets
        </b>
      </a>
    );
  };

  private readonly toggleShowTweets = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  private readonly renderTweets = () => {
    return <>{this.props.postIds.map(this.tweetIdToComponent)}</>;
  };

  private readonly tweetIdToComponent = (tweetId: number, index: number) => {
    return (
      <div className='row'>
        <div className='col'>
          <TwitterTweetEmbed
            key={index}
            tweetId={tweetId.toString()}
            options={{ cards: 'hidden', width: '100%', align: 'middle' }}
          />
        </div>
      </div>
    );
  };
}

export default Tweets;

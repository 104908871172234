import React, { Component } from 'react';
import './App.css';
import Emoji from './emoji/Emoji';
import { INITIAL, LOADING, NOT_FOUND } from './emoji/EmojiConstants';
import navItems from './views/core/ViewNavConfig';

const FALLBACK_EMOJI_MAPPING = {
  emojiCode: NOT_FOUND,
  emojiText: `Couldn't find a matching emoji. This could be an issue with the input. Try some other entry!`
};
interface AppState {
  emojiCode: string;
  emojiText: string;
  isLoading: boolean;
  activeView: { name: string; component: React.ReactNode };
}
class App extends Component<{}, AppState> {
  private readonly START_SATE = {
    isLoading: false,
    emojiCode: INITIAL,
    emojiText:
      'See how any text translates to an emoji using artificial intelligence.',
    activeView: navItems[0]
  };

  state = this.START_SATE;

  render() {
    const { activeView, emojiCode, emojiText, isLoading } = this.state;
    const View = activeView.component;

    return (
      <div className='App container'>
        <Emoji code={isLoading ? LOADING : emojiCode} />

        <div className='row justify-content-center'>
          <div className='col text-center'>
            {isLoading ? 'Analyzing...' : emojiText}
          </div>
        </div>

        <br />

        <div className='row'>
          <div className='col'>
            <ul className='nav nav-pills justify-content-center'>
              {navItems.map(this.renderNavItem)}
            </ul>
          </div>
        </div>

        <br />

        <View
          isLoading={isLoading}
          onAnalysisStarted={this.handleAnalysisStarted}
          onAnalysisCompleted={this.handleAnalysisCompleted}
          onReset={this.handleReset}
          onError={this.handleError}
        />
      </div>
    );
  }

  private readonly renderNavItem = (navItem: {
    name: string;
    component: React.ReactNode;
  }) => {
    const linkClasses = ['nav-link'];

    if (this.state.activeView.name === navItem.name) {
      linkClasses.push('active');
    }
    return (
      <li className='nav-item'>
        <a
          className={linkClasses.join(' ')}
          style={{ cursor: 'pointer' }}
          onClick={this.handleNavClick}>
          {navItem.name}
        </a>
      </li>
    );
  };

  private readonly handleNavClick = (event: React.SyntheticEvent) => {
    if (event.target instanceof HTMLElement) {
      const name = event.target.innerHTML;
      const view = navItems.filter(item => name === item.name);

      this.setState({ activeView: view[0] });
    }
  };

  private readonly handleAnalysisStarted = () => {
    this.setState({ isLoading: true });
  };

  private readonly handleAnalysisCompleted = (
    result: {
      emojiCode: string;
      emojiText: string;
    } | null
  ) => {
    const emojiState = result || FALLBACK_EMOJI_MAPPING;

    this.setState({
      isLoading: false,
      ...emojiState
    });
  };

  private readonly handleReset = () => {
    this.setState(this.START_SATE);
  };

  private readonly handleError = () => {
    const newState = this.START_SATE;
    newState.emojiText =
      'Something went wrong. Go ahead and try again, or come back later when things are settled.';

    this.setState(newState);
  };
}

export default App;

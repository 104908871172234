import * as React from 'react';

interface AnalyzeResetProps {
  isLoading: boolean;
  isInputValid: () => boolean;
  handleAnalyzeClick: () => void;
  onReset: () => void;
}
const AnalyzeReset = (props: AnalyzeResetProps) => {
  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        aria-label='Get Emoji'
        disabled={props.isInputValid()}
        onClick={props.handleAnalyzeClick}>
        {props.isLoading ? '...Loading' : 'Get Emoji'}
      </button>{' '}
      <button
        type='button'
        className='btn btn-secondary'
        aria-label='Reset'
        onClick={props.onReset}>
        Reset
      </button>
    </>
  );
};

export default AnalyzeReset;

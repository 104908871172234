import 'whatwg-fetch';

const BASE_PATH = `${process.env.REACT_APP_BASE_URL}`;

type GetSentimentEmojiResponse = {
  name: string;
  code: string;
  threshold: number;
};
type GetTwitterSentimentEmojiResponse = {
  emoji: GetSentimentEmojiResponse;
  postIds: string[];
};
type QueryParamArgs = { [args: string]: string | number };

const buildUrlQueryString = (nlpPath: string, queryParams: QueryParamArgs) => {
  const query: string[] = [];
  Object.keys(queryParams).forEach(param =>
    query.push(`${param}=${queryParams[param]}`)
  );

  return `${BASE_PATH}${nlpPath}?${query.join('&')}`;
};

const fetchNlpRequest = (nlpPath: string, queryParams: QueryParamArgs) => {
  const url = buildUrlQueryString(nlpPath, queryParams);

  return fetch(url, {
    credentials: 'include'
  }).then(response => response.json());
};

const getSentimentEmoji = (
  text: string
): Promise<GetSentimentEmojiResponse> => {
  return fetchNlpRequest('/nlp/sentiment/emoji', { text: text });
};

const getSentimentEmojiFromTwitterUser = (
  userId: string
): Promise<GetTwitterSentimentEmojiResponse> => {
  return fetchNlpRequest('/nlp/sentiment/twitteremoji', {
    userId: userId
  }).then((json: GetTwitterSentimentEmojiResponse) => json);
};

const getSentimentEmojiFromTwitterGeocode = (
  lat: number,
  long: number,
  radiusMiles: number
): Promise<GetTwitterSentimentEmojiResponse> => {
  return fetchNlpRequest('/nlp/sentiment/twitteremojiarea', {
    lat: lat,
    long: long,
    radius: `${radiusMiles}mi`
  }).then((json: GetTwitterSentimentEmojiResponse) => json);
};

export {
  getSentimentEmoji,
  getSentimentEmojiFromTwitterUser,
  getSentimentEmojiFromTwitterGeocode
};
